<template>
  <div>
    <el-container>
      <el-main style="background: #ffffff; height: 100%">
        <head-layout
          @head-save="trainingNoticeSaving()"
          @head-saveBack="headSubmit()"
          @head-startTraining="startTraining()"
          @head-cancel="headCancel"
          :head-btn-options="headBtnOptions"
          @head-sign="toSee()"
          head-title="培训通知"
        >
        </head-layout>
        <div style="padding: 12px">
          <el-form ref="form" :model="form" :rules="rules" label-width="150px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="培训主题:" prop="trainTheme">
                  <el-input
                    type="text"
                    v-model="form.trainTheme"
                    :disabled="
                     this.type != 'other' && this.type != 'add' && this.type != 'curriculumCenter'
                    "
                    :placeholder="'请输入培训主题'"
                    maxlength="50"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!--培训时间 时间范围选择-->
                <el-form-item label="培训时间:" prop="trainTimeRange">
                  <el-date-picker
                    v-model="form.trainTimeRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    style="width: 100%"
                    :disabled="
                     this.type != 'other' && this.type != 'add' && this.type != 'curriculumCenter'
                    "
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <!--              <el-col :span="8">-->
              <!--                <el-form-item label="培训方式:" prop="trainWay">-->
              <!--                  <el-radio-group v-model="form.trainWay" :disabled="readonly">-->
              <!--                    <el-radio :label="2">线下</el-radio>-->
              <!--                    <el-radio :label="1">线上</el-radio>-->
              <!--                  </el-radio-group>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :span="8">
                <el-form-item label="课程类型:" prop="courseType">
                  <el-radio-group
                    v-model="form.courseType"
                    :disabled="this.type != 'other' && this.type != 'add' && this.type != 'curriculumCenter'"
                  >
                    <el-radio label="1">继续教育</el-radio>
                    <el-radio label="2">场景培训</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <!--培训计划 计划内 计划外，计划内支持选择-->
                <el-form-item label="年度培训计划:" prop="trainPlan">
                  <el-radio-group
                    v-model="form.trainPlan"
                    :disabled="
                      this.type != 'other'&&this.type != 'add' && this.type != 'curriculumCenter'
                    "
                    @change="handleTrainPlanChange"
                  >
                    <el-radio :label="1">计划内</el-radio>
                    <el-radio :label="2">计划外</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  v-if="form.trainPlan == '1'"
                  label="计划培训名称:"
                  prop="trainingPlanId"
                >
                  <el-select
                    v-model="form.trainingPlanId"
                    placeholder="请选择计划培训名称"
                    :disabled="
                     this.type != 'other'&&this.type != 'add' && this.type != 'curriculumCenter'
                    "
                  >
                    <el-option
                      v-for="item in trainingPlans"
                      :key="item.id"
                      :label="item.trainMessage"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!--              <el-col :span="8">-->
              <!--                <el-form-item-->
              <!--                  label="级别教育:"-->
              <!--                  prop="levelEducation"-->
              <!--                >-->
              <!--                  <el-select-->
              <!--                    v-model="form.levelEducation"-->
              <!--                    placeholder="请选择级别教育"-->
              <!--                    :popper-append-to-body="false"-->
              <!--                    :disabled="readonly"-->
              <!--                  >-->
              <!--                    <el-option-->
              <!--                      v-for="item in levelEducationList"-->
              <!--                      :key="item.dictKey"-->
              <!--                      :label="item.dictValue"-->
              <!--                      :value="item.dictKey"-->
              <!--                    >-->
              <!--                    </el-option>-->
              <!--                  </el-select>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="备注:" prop="trainMessage">
                  <el-input
                    type="textarea"
                    v-model="form.trainMessage"
                    :disabled="
                      this.type != 'other'&&this.type != 'add' && this.type != 'curriculumCenter'
                    "
                    maxlength="200"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-tabs v-model="activeName">
              <el-tab-pane label="课程信息" name="courseInformation">
                <div>
                  <div class="formTopic">
                    <span style="font-size: 14px; margin-left: 6px">课程信息</span>
                  </div>
                  <onTheJobTraining
                    ref="onTheJobTraining"
                    :chooseACourseTask="chooseACourseTask"
                    :trainingTaskList="trainingTaskList"
                    @course="courseMethod"
                  ></onTheJobTraining>
                </div>
              </el-tab-pane>
              <el-tab-pane label="参与培训人员" name="trainingPersonnel">
                <div style="margin-top: 12px">
                  <div class="formTopic">
                    <span style="font-size: 14px; margin-left: 6px">参与培训人员</span>
                    <div>
                      <el-button
                        type="primary"
                        size="mini"
                        style="height: 28px; margin-top: 6px"
                        @click="handleUserModeOpen"
                        v-if="type != 'view'"
                      >
                        新增
                      </el-button>
                      <el-button
                        type="primary"
                        size="mini"
                        style="height: 28px; margin-top: 6px;margin: 10px;"
                        @click="testPaperDownload"
                        v-if="type != 'view'"
                      >
                        试卷下载
                      </el-button>
                    </div>
                  </div>
                  <grid-head-layout
                    ref="searchForm"
                    :search-columns="noticePersonColumns"
                    @grid-head-search="noticePersonSearch"
                    @grid-head-empty="noticePersonRest"
                  />
                  <el-table
                    :data="noticePersonTableData"
                    size="medium"
                    height="calc(100vh - 500px)"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                  >
                    <el-table-column
                      type="selection"
                      width="55">
                    </el-table-column>
                    <el-table-column
                      type="index"
                      label="序号"
                      align="center"
                      width="80"

                    >
                    </el-table-column>
                    <el-table-column prop="userName" label="姓名" align="center">
                    </el-table-column>
                    <el-table-column prop="deptName" label="组织" align="center">
                    </el-table-column>
                    <el-table-column prop="totalHours" label="总课时" align="center">
                    </el-table-column>
                    <el-table-column prop="standardsHours" label="达标课时" align="center">
                    </el-table-column>
                    <el-table-column prop="accomplishAccomplish" label="完成必修课时" align="center">
                    </el-table-column>
                    <el-table-column prop="type" label="培训方式" align="center" :formatter="formatIsCompulsory">
                    </el-table-column>
                    <el-table-column prop="isAccomplish" label="是否完成" align="center" :formatter="completeOrNot">
                    </el-table-column>

                    <el-table-column label="操作" width="150">
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          type="text"
                          v-if="scope.row.isAccomplish"
                          @click="viewDetails(scope.row)"
                        >查看详情
                        </el-button>
                        <el-button
                          size="mini"
                          type="text"
                          v-if="scope.row.isAccomplish&&type != 'view'"
                          @click="recordScores(scope.row)"
                        >成绩上传
                        </el-button>
                        <el-button
                          v-if="type != 'view'"
                          size="mini"
                          type="text"
                          @click="handleDelete(scope.row)"
                        >删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <CommonDialog v-if="deptShow" width="90%" dialogTitle="参加培训人员" @cancel="deptShow = false" @confirm="selectAllData">
      <userDepartmentSeparationDialog
        ref="userDepartmentSeparationDialog"
        v-if="deptShow"
        :disabled-list="disabledErpItemList"
      ></userDepartmentSeparationDialog>
    </CommonDialog>
    <CommonDialog v-if="courseDialog" class="courseSelection" :showConfirmBtn="false" width="85%" dialogTitle="课程"
                  @cancel="courseDialog = false">
      <publicCurriculumPopUp
        ref="publicCurriculumPopUp"
        :courseType="courseType"
        @curriculumSubmit="curriculumSubmit"
      ></publicCurriculumPopUp>
    </CommonDialog>
    <CommonDialog v-if="showFileDialog" :showConfirmBtn="false" width="70%" dialogTitle="附件预览"
                  @cancel="showFileDialog = false">
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
    </CommonDialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import userDepartmentSeparationDialog from "@/views/components/UserDeptDialog/userDepartmentSeparationDialog.vue";
import {
  trainingTaskDetail,
  trainingTaskSubmit,
  gettrainingPlanpage,
  trainingUpdateSign,
  trainingTaskRemove,
  removeUserByUserId,
} from "@/api/safe/index";
import website from "@/config/website";
import {dictionaryBiz} from "@/api/reportTasks";
import publicCurriculumPopUp
  from "@/views/business/safetyenment/train/trainingTaskList/components/publicCurriculumPopUp";
import {getDetailBySiteTrainingId} from "@/api/training/recording";
import {
  saveCorrespondingData,
  whetherToStartTraining,
} from "@/api/training/trainGroupScene";
import {dateFormat} from "@/util/date";
import {companyProjects} from "@/api/system/dept";
import onTheJobTraining from "@/views/business/knowledge/onTheJobTraining/addNew.vue";
import CommonDialog from "@/components/CommonDialog";
import {fileDownload} from "@/api/onTheJobTraining/training";

import JSZip from "jszip";
import FileSaver from "file-saver";
import {removecwList} from "@/api/hazardousChemicals";

export default {
  components: {
    HeadLayout,
    CommonTree,
    GridLayout,
    userDepartmentSeparationDialog,
    publicCurriculumPopUp,
    onTheJobTraining,
    CommonDialog
  },
  data() {
    return {
      activeName: "courseInformation",//table选项卡切换
      trainingType: 1,
      readonly: false,
      tableData: [],
      deptShow: false,
      fileList: [],
      disabledErpItemList: [],
      trainingPlans: [], //培训计划
      courseTypeList: [], //课程类型
      levelEducationList: [], //级别教育
      courseDialog: false,
      courseType: "1",
      trainingTaskList: "", //传入课程的状态
      noticePersonPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      condition: "", //条件
      userId: "",
      deptId: "",
      submitLoading: false,
      showFileDialog: false,
      fileUrl: "",
      type: "",
      trainTimeRange: "",
      startTrainingButton: false, //开始培训按钮
      // trainTimeRange: [new Date(), this.getCurrentTime()],
      dicData: [{dictKey: "1", dictValue: "线上"}, {dictKey: "2", dictValue: "线下"}],
      completedTrainingOrNot: [],

      form: {
        levelEducation: "",
        courseType: "2",
        id: "",
        trainTheme: "",
        deptRelationId: "",
        trainTimeRange: "",
        roleRelationId: "",
        trainStart: "",
        trainEnd: "",
        trainPlan: 2, //培训计划1:计划内 2:计划外
        trainingPlanId: "",
        fileId: "",
        knowledgeFileId: "",
        sysAttachList: [],
        trainWay: 2, //培训方式1:线上 2:线下
        examPaperId: "",
        paperName: "",
        trainMessage: "",
        groupCode: "", //机构分组
        roleIds: "",
        status: 1, //状态草稿,
        userRelationList: [],
      },
      rules: {
        trainTheme: [
          {required: true, message: "请输入培训主题", trigger: "change"},
        ],
        trainTimeRange: [
          {required: true, message: "请选择培训日期", trigger: "change"},
        ],
        trainingPlanId: [
          {required: true, message: "请选择培训计划", trigger: "change"},
        ],
      },
      noticePersonTableData: [],
      allNoticePersonTableData: [],
      noticePersonTableLoading: false,
    };
  },
  computed: {
    ...mapGetters(["tag", "roleData", "userInfo", "themeColor"]),
    headBtnOptions() {
      let buttonBtn = [];
      if (
        ["add", "edit", "other", "curriculumCenter"].includes(this.type) ||
        ["supplementaryDutyPersonnel"].includes(this.type)
      ) {
        buttonBtn.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        });
        buttonBtn.push({
          label: "保存并返回",
          emit: "head-saveBack",
          type: "button",
          icon: "",
          btnOptType: "saveBack",
        });
      }
      if (["add", "edit", "other", "curriculumCenter"].includes(this.type)) {
        buttonBtn.push({
          label: "开始培训",
          emit: "head-startTraining",
          type: "button",
          icon: "",
          loading: this.startTrainingButton,
        });
      }
      if (["view"].includes(this.type) && this.condition == 1) {
        buttonBtn.push({
          label: "签收",
          emit: "head-sign",
          type: "button",
          icon: "",
          btnOptType: "sign",
        });
      }
      buttonBtn.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        icon: "",
        btnOptType: "cancel",
      });
      return buttonBtn;
    },
    noticePersonColumns() {
      return [
        {
          placeholder: "请输入姓名",
          type: "input",
          prop: "userName",
          span: 4,
        },
        {
          placeholder: "请输入组织名称",
          type: "input",
          prop: "deptName",
          span: 4,
        },
      ];
    },
    noticePersonTableOptions() {
      return {
        indexLabel: "序号",
        index: true,
        column: [
          {
            label: "姓名",
            prop: "name",
            align: "center",
            overHidden: true,
          },

          {
            label: "组织",
            prop: "deptName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
    tableOptions() {
      return {
        selection: false,
        indexLabel: "序号",
        index: true,
        column: [
          {
            label: "课程名称",
            prop: "courseName",
            align: "left",
            overHidden: true,
          },

          {
            label: "培训类型",
            prop: "trainingScenarios",
            width: 200,
            align: "center",
            overHidden: true,
          },

          {
            label: "课程类型",
            prop: "courseType",
            align: "center",
            width: 150,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=course_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            overHidden: true,
          },
        ],
      };
    },
  },
  mounted() {
    var courseId = sessionStorage.getItem("courseId")
    // var courseId = "1814247914285080578"
    var item = sessionStorage.getItem("taskType");
    if (typeof item != "undefined" && item == "other") {
      if (courseId > 0) {
        this.$refs.onTheJobTraining.initDate(
          courseId
        );
      }
      var item1 = sessionStorage.getItem("userList");
      const dataArray = JSON.parse(item1);
      if (dataArray) {
        this.noticePersonTableData = []
        dataArray.forEach((e) => {
          this.noticePersonTableData.push({
            userName: e.userName,
            userId: e.userId,
            deptId: e.deptId,
            deptName: e.deptName,
            type: 1,
            storageMode: "trainingTask"
          });
        });
        this.allNoticePersonTableData = this.noticePersonTableData
        console.log("this.form.educations", this.form.educations)
        sessionStorage.removeItem("userList")

      }
    }
  },
  created() {
    gettrainingPlanpage(1, 999).then((res) => {
      const data = res.data.data;
      this.trainingPlans = data.records;
    });
    dictionaryBiz("course_type").then((res) => {
      this.courseTypeList = res.data.data;
    });
    dictionaryBiz("education_is_accomplish").then((res) => {
      this.completedTrainingOrNot = res.data.data;
    });
    let {id, type, condition, userId, deptId, row} = this.$route.query;
    if (id != undefined) {
      this.id = id;
    } else {
      this.id = sessionStorage.getItem("taskId");
    }
    if (type != undefined) {
      this.type = type;
    } else {
      let startDate = dateFormat(new Date(), "yyyy-MM-dd");
      let endDate = dateFormat(new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000), "yyyy-MM-dd");
      this.form.trainTimeRange = [startDate, endDate]
      this.type = sessionStorage.getItem("taskType");
    }
    console.log("进行输出-------", this.type)
    if (this.type == "add" || this.type == "curriculumCenter" || this.type == "other") {
      this.trainingTaskList = "trainingTaskList"
    } else {
      this.trainingTaskList = "trainingTaskListView"
    }
    if (["curriculumCenter"].includes(this.type)) {
      this.readonly = false;
      let curriculumCenterRow = JSON.parse(row);
      let startDate = dateFormat(new Date(), "yyyy-MM-dd");
      let endDate = dateFormat(new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000), "yyyy-MM-dd");
      this.form.trainTimeRange = [startDate, endDate]
      this.form.courseType = curriculumCenterRow.courseType;
      this.curriculumSubmit(curriculumCenterRow);
    } else if (["supplementaryDutyPersonnel"].includes(this.type)) {
      this.readonly = true;
      this.init(this.id);
      // let curriculumCenterRow = JSON.parse(row);
      // this.curriculumSubmit(curriculumCenterRow)
    } else if (this.type != "other") {
      if (["view"].includes(this.type)) {
        this.condition = condition;
        this.userId = userId;
        this.deptId = deptId;
        this.readonly = true;
      } else {
        this.readonly = false;
      }
      if (["edit", "view"].includes(this.type)) {
        this.init(this.id);
      }
    }

  },
  methods: {
    //批量下载文件
    batchDownload(urlList) {
      var blogTitle = `附件_${new Date().getTime()}`; // 下载后压缩包的名称
      var zip = new JSZip();
      var promises = [];
      let cache = {};
      for (let item of urlList) {
        // item.url为文件链接地址
        // item.originalName为文件名称
        if (item.url) {
          const promise = this.getImgArrayBuffer(item.url).then((data) => {
            // 下载文件, 并存成ArrayBuffer对象(blob)
            zip.file(item.originalName, data, {binary: true}); // 逐个添加文件
            cache[item.originalName] = data;
          });
          promises.push(promise);
        } else {
          // url地址不存在时提示
          this.$message.error(`附件${item.originalName}地址错误，下载失败`);
          console.log(`附件${item.originalName}地址错误，下载失败`);
        }
      }
      Promise.all(promises)
        .then(() => {
          zip.generateAsync({type: "blob"}).then((content) => {
            // 生成二进制流
            FileSaver.saveAs(content, blogTitle); // 利用file-saver保存文件  blogTitle:自定义文件名
          });
        })
        .catch((res) => {
          this.$message.error("文件压缩失败");
        });
    },
    //文件以流的形式获取（参数url为文件链接地址）
    getImgArrayBuffer(url) {
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", url, true);
        xmlhttp.responseType = "blob";
        xmlhttp.onload = function () {
          if (xmlhttp.status == 200) {
            resolve(xmlhttp.response);
          } else {
            reject(xmlhttp.response);
          }
        };
        xmlhttp.send();
      });
    },
    /**
     * 试卷下载
     */
    testPaperDownload() {
      if (!this.multipleSelection) {
        return this.$message({
          type: "warning",
          message: "请先选择人员",
        });
      }
      this.form.educations = this.multipleSelection;
      fileDownload(this.form).then((res) => {
        if (res.data.code == 200) {
          let urlList = new Array();
          let data = res.data.data;
          data.forEach((item) => {
            urlList.push({
              originalName: item.originalName,
              url: item.link
            })
          })
          this.batchDownload(urlList)
          return this.$message({
            type: "success",
            message: "操作成功",
          });
        } else {
          return this.$message({
            type: "warning",
            message: res.data.msg,
          });
        }
      })
    },
    /**
     * 选中的数据
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    curriculumSubmit(row) {
      //点击确认后清空上一条数据
      if (typeof this.tableData != "undefined" && this.tableData.length == 1) {
        this.empty();
      }
      this.$nextTick(() => {
        this.$refs.onTheJobTraining.initDate(row.id);
        let cc = [];
        //将id制空方便保存数据
        row.trainingNoticeId = row.id;
        row.id = "";
        cc.push(row);
        this.tableData = cc;
        console.log("进行输出---------tableData-------", this.tableData)
        this.courseDialog = false;
      });
    },
    empty() {
      this.toDel(this.tableData[0], 0);
    },
    curriculum(row) {
      this.$nextTick(() => {
        let cc = [];
        //将id制空方便保存数据
        // row.trainingNoticeId = "1814247914285080578";
        row.trainingNoticeId = sessionStorage.getItem("courseId");
        row.id = "";
        cc.push(row);
        this.tableData = cc;
        this.courseDialog = false;
        sessionStorage.removeItem("courseId")
        sessionStorage.removeItem("taskType")
      });
    },
    courseMethod(row) {
      if (sessionStorage.getItem("taskType") == "other" && sessionStorage.getItem("courseId") > 1) {
        this.curriculum(row.data.data)
      } else {
        sessionStorage.removeItem("courseId")
        sessionStorage.removeItem("taskType")
      }
    },
    chooseACourseTask() {
      // if (typeof this.tableData != "undefined" && this.tableData.length == 1) {
      //   return this.$message({
      //     type: "warning",
      //     message: "只允许保存一条数据，请先将数据清空！",
      //   });
      // }
      if (!this.form.courseType) {
        return this.$message({
          type: "warning",
          message: "请先选择课程类型",
        });
      }
      this.courseDialog = true;
      console.log("this.fromthis.fromthis.from", this.form);
      this.courseType = this.form.courseType;
    },
    formatIsCompulsory(row, column) {
      const value = row[column.property];
      const dict = this.dicData.reduce((acc, current) => {
        acc[current.dictKey] = current.dictValue;
        return acc;
      }, {});
      return dict[value];
    },
    completeOrNot(row, column) {
      const value = row[column.property];
      const dict = this.completedTrainingOrNot.reduce((acc, current) => {
        acc[current.dictKey] = current.dictValue;
        return acc;
      }, {});
      return dict[value];
    },
    handleAvatarSuccess(res, file, fileList) {
      const parts = file.response.data.originalName.split("."); // 使用 . 分割字符串
      const extension = parts[parts.length - 1]; // 获取最后一个部分作为后缀名
      this.fileList.push({
        extension: extension,
        id: file.response.data.attachId,
        link: file.response.data.link,
        originalName: file.response.data.originalName,
        createTime: this.sinoma.toDate(
          new Date().getTime(),
          "yyyy-MM-dd HH:mm:ss"
        ),
      });
      this.$message({
        type: "success",
        message: "上传成功！",
      });
    },
    handleClick(file) {
      let a = document.createElement("a");
      a.href = file.link;
      a.click();
    },
    handleDeleteFile(file) {
      this.$confirm("确定将数据删除？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.fileList = this.fileList.filter((item) => {
          if (item.id != file.id) {
            return item;
          }
        });
      });
    },
    handleViewFile(file) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(file.link));
      this.showFileDialog = true;
    },
    //获取一小时之前的日期
    getCurrentTime() {
      return new Date(new Date().getTime() + 1 * 60 * 60 * 1000); // 前一个小时  当前时间
    },
    rowDelete(index) {
      this.tableData.splice(index, 1);
      this.tableData = [];
    },
    toDel(row, index) {
      // this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
      //   confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
      //   cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
      //   type: "warning",
      // }).then(async () => {
      this.$refs.onTheJobTraining.empty();
      this.rowDelete(index);
      if (row.id) {
        trainingTaskRemove(row.id);
      }
      //   this.$message({
      //     type: "success",
      //     message: this.$t("cip.cmn.msg.success.operateSuccess"),
      //   });
      // });
    },
    //前端时间格式的转换
    timeChange(UTCDateString) {
      if (!UTCDateString) {
        return "-";
      }

      function formatFunc(str) {
        return str > 9 ? str : "0" + str;
      }

      var date2 = new Date(UTCDateString);
      var year = date2.getFullYear();
      var mon = formatFunc(date2.getMonth() + 1);
      var day = formatFunc(date2.getDate());
      var hour = date2.getHours();
      //var noon = hour >= 12 ? 'PM' : 'AM'; // 判断是上午还是下午
      hour = hour >= 12 ? hour : hour; // 24小时制
      //hour = hour >= 12 ? hour - 12 : hour; // 12小时制
      hour = formatFunc(hour);
      var min = formatFunc(date2.getMinutes());
      var sec = formatFunc(date2.getSeconds());
      var dateStr =
        year + "-" + mon + "-" + day + " " + hour + ":" + min + ":" + sec;
      return dateStr;
    },
    trainingNoticeSaving(res, isTraining) {
      if (!this.isDateBeforeCurrent(this.form.trainTimeRange[1])) {
        return this.$message({
          type: "warning",
          message: "结束时间不能小于当前时间",
        });
      }
      let form = this.$refs.onTheJobTraining.form;
      if (form.totalHours < form.meetLesson) {
        return this.$message({
          type: "warning",
          message: "课程的达标课时不能超过合计课时",
        });
      }
      if (form.totalHours > 0 && form.meetLesson <= 0) {
        return this.$message({
          type: "warning",
          message:
            "请输入达标课时,达标课时不能为0",
        });
      }
      const _that = this;
      if (this.tableData.length > 0) {
        if (this.form.courseType != this.tableData[0].courseType) {
          return this.$message({
            type: "warning",
            message: "课程类型与表格内的课程类型不一致，请修改课程类型",
          });
        }
      }
      if (!_that.form.id) {
        this.$confirm("保存后无法在进行修改课程信息", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            _that.storageMethod(_that);
          })
      } else {
        _that.storageMethod(_that);
      }

    },
    storageMethod(_that) {
      _that.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          this.startTrainingButton = true;
          let fileIds = "";
          if (_that.fileList) {
            let fileId = [];
            _that.fileList.forEach((item) => fileId.push(item.id));
            fileIds = fileId.join(",");
          }
          //获取关联的课程的所有信息，然后进行数据处理
          if (!_that.form.id) {
            _that.form.kmTrainGroupSceneVO = _that.$refs.onTheJobTraining.form;
          }
          _that.form.trainStart = _that.timeChange(this.form.trainTimeRange[0]);
          _that.form.trainEnd = _that.timeChange(this.form.trainTimeRange[1]);
          _that.form.fileId = fileIds;
          _that.$refs.searchForm.searchForm.userName = "";
          _that.$refs.searchForm.searchForm.deptName = "";
          _that.noticePersonTableData = _that.allNoticePersonTableData;
          _that.form.userRelationList = _that.noticePersonTableData;
          _that.form.status = 2;
          _that.form.trainingTrainingCourse = _that.tableData;
          _that.form.whetherToStartTraining = "startTraining";
          _that.form.educations = _that.form.userRelationList;
          _that.form.trainingStatus = 1;
          trainingTaskSubmit(_that.form).then((res) => {
            if (res.data.code == 200) {
              _that.form.id = res.data.data.id;
              this.$message({
                type: "success",
                message: this.$t("cip.cmn.msg.success.operateSuccess"),
              });
              this.init(_that.form.id, "startTraining");
              sessionStorage.setItem("taskId", res.data.data.id);
              sessionStorage.setItem("taskType", "edit");
            }
            this.startTrainingButton = false;
          });
          this.$loading().close();
        }
      })
    },
    headSave(res, isTraining) {
      const _that = this;
      _that.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          let fileIds = "";
          if (_that.fileList) {
            let fileId = [];
            _that.fileList.forEach((item) => fileId.push(item.id));
            fileIds = fileId.join(",");
          }
          //获取关联的课程的所有信息，然后进行数据处理
          if (!_that.form.id) {
            _that.form.kmTrainGroupSceneVO = _that.$refs.onTheJobTraining.form;
          }
          _that.form.trainStart = _that.timeChange(this.form.trainTimeRange[0]);
          _that.form.trainEnd = _that.timeChange(this.form.trainTimeRange[1]);
          _that.form.fileId = fileIds;
          _that.$refs.searchForm.searchForm.userName = "";
          _that.$refs.searchForm.searchForm.deptName = "";
          _that.noticePersonTableData = _that.allNoticePersonTableData;
          _that.form.userRelationList = _that.noticePersonTableData;
          _that.form.status = 2;
          _that.form.whetherToStartTraining = "startTraining";
          _that.form.trainingTrainingCourse = _that.tableData;
          _that.form.educations = _that.form.userRelationList;
          if (res == "startTraining") {
            _that.form.whetherToStartTraining = "startTraining";
          }
          trainingTaskSubmit(_that.form)
            .then((res) => {
              if (res.data.code == 200) {
                _that.form = res.data.data;
                this.$message({
                  type: "success",
                  message: this.$t("cip.cmn.msg.success.operateSuccess"),
                });
                sessionStorage.setItem("taskId", res.data.data.id);
                sessionStorage.setItem("taskType", "edit");
              }
            })
            .then(() => {
              if (isTraining) {
                this.startTrainingMethod(this.form);
              }
            });
          this.$route.query.type = "edit";
          this.type = "edit";
          this.$loading().close();
        }
      });
    },
    recordScores(row) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingEducation/view",
        query: {
          id: row.id,
          type: "recordScores"
        },
      });
    },
    /**
     * 跳转我的培训界面
     * @param row
     */
    viewDetails(row) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingEducation/view",
        query: {
          id: row.id,
          type: "view"
        },
      });
    },
    handleDelete(row) {
      this.$confirm("确认删除当前数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.noticePersonTableData = this.noticePersonTableData.filter((item) => {
            if (item.userId != row.userId || item.deptId != row.deptId) {
              return item;
            }
          });
          this.allNoticePersonTableData = this.noticePersonTableData;
          if (row.id) {
            removeUserByUserId({
              id: row.id,
              taskId: this.form.id,
              userId: row.userId,
            });
          }
        })
    },
    //开始培训
    startTrainingMethod(row) {
      getDetailBySiteTrainingId(row.id).then((res) => {
        if (res.data.code == 200 && res.data.msg != "暂无承载数据") {
          if (res.data.data) {
            window.open(window.location.origin + '#/business/training/onSiteTraining/edit?row=' + JSON.stringify(res.data.data) + '&type=edit');
          }
        } else {
          //需要查询课程是否失效
          if (this.tableData[0].status == 1) {
            let status = 1;
            // whetherToStartTraining(row.id, status).then((res) => {
            //   if (res.data.code == 200) {
            let time = dateFormat(new Date(), "yyyy-MM-dd");
            companyProjects().then((res) => {
              if (res.data.code == "200") {
                let data = {
                  siteTrainingId: row.id,
                  trainingName: row.courseName,
                  trainingTime: time,
                  trainingScenarios: row.trainingScenarios,
                  sponsor: this.userInfo.user_id,
                  project: res.data.data.project,
                  isFinish: 1,
                  firm: res.data.data.firm,
                  remark: row.remark,
                  trainingType: "startTraining",
                };
                saveCorrespondingData(data).then((res) => {
                  if (res.data.code == 200) {
                    window.open(window.location.origin + '#/business/training/onSiteTraining/edit?row=' + JSON.stringify(res.data.data) + '&type=edit');
                  } else {
                    this.$message.success(msg);
                  }
                });
              } else {
                this.$message.success("数据错误,请刷新重试!");
              }
            });
            // }
            // })
          } else {
            this.$message.warning("此任务已失效!");
          }
        }
      });
    },
    /**
     * 开始培训
     */
    startTraining() {
      if (!this.isDateBeforeCurrent(this.form.trainTimeRange[1])) {
        return this.$message({
          type: "warning",
          message: "结束时间不能小于当前时间",
        });
      }
      let form = this.$refs.onTheJobTraining.form;
      if (form.totalHours < form.meetLesson) {
        return this.$message({
          type: "warning",
          message: "课程的达标课时不能超过合计课时",
        });
      }
      if (form.totalHours > 0 && form.meetLesson <= 0) {
        return this.$message({
          type: "warning",
          message:
            "请输入达标课时,达标课时不能为0",
        });
      }
      if (!this.form.id) {
        this.$confirm("开始培训后无法在进行修改课程信息", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.startTrainingCall();
        })
      } else {
        this.startTrainingCall();
      }
    },
    startTrainingCall() {
      //获取课程信息
      if (this.trainingType == 1) {
        // 培训状态类型为1（未开始）首次进行开始培训的逻辑
        if (this.tableData.length > 0) {
          this.headSave("startTraining", true);
        } else {
          return this.$message({
            type: "warning",
            message: "请选择课程",
          });
        }
      } else if (this.trainingType == 2) {
        this.continuingTraining(this.form);
      } else {
        return this.$message.warning("当前培训任务已结束！");
      }
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    continuingTraining(row) {
      getDetailBySiteTrainingId(row.id).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data) {
            window.open(window.location.origin + '#/business/training/onSiteTraining/edit?row=' + JSON.stringify(res.data.data) + '&type=edit');
          }
        }
      });
    },
    isDateBeforeCurrent(trainingEndTime) {
      if (!trainingEndTime) return false;
      const currentTime = new Date();
      currentTime.setHours(0, 0, 0, 0);
      const endTime = new Date(trainingEndTime);
      endTime.setHours(0, 0, 0, 0);
      return currentTime <= endTime;
    },
    headSubmit() {
      let _that = this;
      if (!this.isDateBeforeCurrent(this.form.trainTimeRange[1])) {
        return this.$message({
          type: "warning",
          message: "结束时间不能小于当前时间",
        });
      }
      let form = _that.$refs.onTheJobTraining.form;
      if (form.totalHours < form.meetLesson) {
        return this.$message({
          type: "warning",
          message: "课程的达标课时不能超过合计课时",
        });
      }
      if (form.totalHours > 0 && form.meetLesson <= 0) {
        return this.$message({
          type: "warning",
          message:
            "请输入达标课时,达标课时不能为0",
        });
      }
      if (this.tableData.length > 0) {
        if (this.form.courseType != this.tableData[0].courseType) {
          return this.$message({
            type: "warning",
            message: "课程类型与表格内的课程类型不一致，请修改课程类型",
          });
        }
      }
      if (!_that.form.id) {
        this.$confirm("保存后无法在进行修改课程信息", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.saveAndReturn(_that)
          })
      } else {
        this.saveAndReturn(_that)
      }
    },
    saveAndReturn(_that) {
      _that.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          //培训时间处理
          let fileIds = "";
          if (_that.fileList) {
            let fileId = [];
            _that.fileList.forEach((item) => fileId.push(item.id));
            fileIds = fileId.join(",");
          }
          //获取关联的课程的所有信息，然后进行数据处理
          if (!_that.form.id) {
            _that.form.kmTrainGroupSceneVO = _that.$refs.onTheJobTraining.form;
          }
          _that.form.trainStart = _that.timeChange(this.form.trainTimeRange[0]);
          _that.form.trainEnd = _that.timeChange(this.form.trainTimeRange[1]);
          _that.noticePersonTableData = _that.allNoticePersonTableData;
          _that.form.userRelationList = _that.noticePersonTableData;
          _that.form.fileId = fileIds;
          _that.form.status = 2;
          console.log("_that.tableData_that.tableData", _that.tableData)
          _that.form.trainingTrainingCourse = _that.tableData;
          _that.form.educations = _that.form.userRelationList;
          _that.form.trainingStatus = 1;
          trainingTaskSubmit(_that.form).then((res) => {
            _that.form = res.data.data;
            this.$router.back();
            this.$loading().close();
          });
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    init(param, res) {
      trainingTaskDetail({id: param}).then((res) => {
        const data = res.data.data;
        console.log(data, "data.trainingType");
        this.trainingType = data.trainingType;
        this.noticePersonTableData = data.educations;
        this.allNoticePersonTableData = data.educations;
        this.disabledErpItemList = this.noticePersonTableData.map(
          (e) => e.userId + "" + e.firm
        );
        this.fileList = data.attachList;
        this.tableData = data.trainingTrainingCourse;
        if (data.trainingTrainingCourse.length > 0) {
          this.$refs.onTheJobTraining.initDate(
            data.trainingTrainingCourse[0].trainingNoticeId,
            param, "task"
          );
          this.$refs.onTheJobTraining.detailsDisableUse()
        }
        this.form = data;
        console.log(this.form, '1080')
        this.form.trainTimeRange = [
          data.trainStart,
          data.trainEnd,
        ];
        // this.form.trainTimeRange = ['2021','2024']
      });
    },
    //签收
    toSee() {
      if (this.form.id) {
        trainingUpdateSign(this.form.id, this.deptId, this.userId).then(
          (res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "签收成功！",
              });
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
          }
        );
      }
    },
    noticePersonSearch(searchparams) {
      this.noticePersonTableData = this.allNoticePersonTableData;
      if (searchparams.userName || searchparams.deptName) {
        this.noticePersonTableData = this.noticePersonTableData.filter(
          (item) => {
            if (searchparams.userName || searchparams.deptName) {
              if (searchparams.userName && searchparams.deptName) {
                if (
                  item.userName.includes(searchparams.userName) &&
                  item.deptName.includes(searchparams.deptName)
                ) {
                  return item;
                }
              } else {
                if (
                  item.userName.includes(searchparams.userName) ||
                  item.deptName.includes(searchparams.deptName)
                ) {
                  return item;
                }
              }
            }
          }
        );
      }
    },
    noticePersonRest() {
      this.noticePersonTableData = this.allNoticePersonTableData;
    },
    handleUserModeOpen() {
      this.disabledErpItemList = this.noticePersonTableData.map(
        (e) => e.userId + "" + e.deptId
      );
      this.deptShow = true;
    },
    selectAllData(data) {
      var confirmTheSubmission = this.$refs.userDepartmentSeparationDialog.confirmTheSubmission();
      //表格里选择的人员为线上培训的数据
      confirmTheSubmission.forEach((item) => {
        this.noticePersonTableData.push({
          userName: item.realName,
          userId: item.userId,
          deptId: item.deptId,
          deptName: item.deptName,
          type: 1,
          storageMode: "trainingTask"
        });
      });
      this.allNoticePersonTableData = this.noticePersonTableData;
      console.log(this.allNoticePersonTableData, "123456");
      this.deptShow = false;
    },
    handleTrainPlanChange() {
      ["trainTimeRange", "trainTheme", "trainingPlanId"].forEach((prop) => {
        this.clearFormFieldError(prop);
      });
    },
    clearFormFieldError(fieldProp) {
      const field = this.$refs.form.fields.find(
        (field) => field.prop === fieldProp
      );
      if (field) {
        field.error = "";
        field.validateStatus = "";
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.common_tree_handle {
  min-width: 188px;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

::v-deep .el-form-item__content {
  .el-tag {
    height: 32px;
    line-height: 32px;
    margin-right: 12px;
    margin-bottom: 5px;

    .el-icon-close {
      font-size: 16px;
    }
  }
}

.left-content {
  box-sizing: border-box;
  background: #fff;

  .content {
    display: flex;
    align-items: center;

    .img-content {
      width: 188px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;

      img {
        width: 188px;
        height: 100px;
      }

      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }

      .file-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }
  }
}

.formTopicCourse {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-weight: 600 !important;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.shuttleBackBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  //margin-bottom: 72px;
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  height: 338px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.deptBox .unitTitle {
  font-size: 13px;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}

::v-deep .el-checkbox-group {
  display: flex;
  overflow-x: auto;
}

::v-deep .el-checkbox {
  margin-right: 5px !important;
}

.paperDialog ::v-deep .el-dialog__body {
  padding: 10px;
}

.knowledge ::v-deep .el-dialog__body {
  padding-top: 20px !important;
}

.paperDialog ::v-deep .el-table__fixed-header-wrapper .el-checkbox__inner {
  display: none !important;
}

.knowledge ::v-deep .el-table__fixed-header-wrapper .el-checkbox__inner {
  display: block !important;
}

.paperDialog ::v-deep #gridLayout {
  height: calc(100% - 56px);
}

.paperDialog ::v-deep .el-tree {
  height: calc(100% - 56px) !important;
}

.paperDialog ::v-deep .el-table {
  height: 392px !important;
}

.paperDialog ::v-deep .el-table__body-wrapper {
  max-height: 358px !important;
  overflow-y: auto;
}

.paperDialog ::v-deep .el-container {
  height: 500px !important;
}

.dialogBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stareBox {
  position: absolute;
  color: #f56c6c;
  left: -134px;
}

.fileBigBox {
  width: 100%;
  max-height: 300px;
  min-height: 100px;
  overflow: auto;
  border-top: 1px solid #bbbbbb;
  margin-top: 12px;
}

.fileBox {
  width: 100%;
  display: flex;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #bbbbbb;
}

.fileBox div {
  width: 25%;
  text-align: center;
  font-size: 14px;
  border-right: 1px solid #bbbbbb;
}

.fileName {
  border-left: 1px solid #bbbbbb;
  padding: 0 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fileName span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fileType {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 6px;
}

//::v-deep .fileName .el-link--inner {
//  width: 100%;
//  text-overflow: ellipsis;
//  overflow: hidden;
//  white-space: nowrap;
//}

.fileOperate {
  display: flex;
  justify-content: center;
}

.fileOperate span {
  cursor: pointer;
  margin: 0 6px;
}

::v-deep .UserDeptMultipleDialog .avue-crud .el-table {
  height: calc(100vh - 384px) !important;
  max-height: calc(100vh - 384px) !important;
}

::v-deep .UserDeptMultipleDialog .avue-tree__content {
  height: calc(100vh - 350px);
}


::v-deep .UserDeptMultipleDialog .el-tree {
  height: calc(100vh - 374px) !important;
}

::v-deep .courseSelection .avue-crud .el-table {
  height: calc(100vh - 460px) !important;
  max-height: calc(100vh - 460px) !important;
}

::v-deep .courseSelection .el-tree {
  height: calc(100vh - 460px) !important;
}
</style>
