<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="行政处罚"
          :head-btn-options="headTopBtnOptions"
          @head-cancel="handleHeadGoback"
          @head-save="headSave(1)"
          @head-save-back="headSave(2)"
        />
        <avue-form :option="formOptions" ref="formLayout" v-model="dataForm" style="padding:10px;" >
          <template slot="acceptOrg">
            <avue-input-tree
              checkStrictly
              default-expand-all
              v-model="dataForm.acceptOrg"
              placeholder="请填写接受单位"
              :dic="organDic"
              :props="{label: 'deptName', value: 'id'}"
              @change="handleChangeContract"
              :disabled="type == 'view'"
            ></avue-input-tree>
          </template>
          <template slot="punishArea">
            <el-cascader
              ref="punishArea"
              v-model="dataForm.punishArea"
              :props="areaOptions"
              clearable
              :disabled="type == 'view'"
              @change="handleAreaChange"
              >
            </el-cascader>
          </template>
        </avue-form>
        <uploader
          @getTableData="handleUpload"
          :fileSize="500"
          accept=".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls,.xlsx"
          :multiple="true"
          :fileOptions="fileOptions"
          ref="uploader"
          :gridRowBtn="fileGridRowBtn"
          :disabled="type == 'view'"
        ></uploader>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {getLazyTree} from "@/api/base/region";

import {
  grgovpunishSubmit,
  grgovpunishDetail,
} from "@/api/responsibility/administrativePenalty";
import { deptChildTree } from "@/api/reportTemplate";
import { mapGetters } from "vuex";
import uploader from "@/components/file-upload/index";
export default {
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    uploader,
  },

  data() {
    return {
      nodeData: {},
      type: "",
      dataForm: {},
      // fileData: [],
      organDic: [],
      fileOptions: {
        linklabel: 'name',
        column: [
          {
            label: "附件名",
            prop: "name",
            align: "left",
            overHidden: true,
          },
          {
            label: "附件格式",
            prop: "extension",
            align: "center",
            overHidden: true,
          },
          {
            label: "上传人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "上传时间",
            prop: "createTime",
            align: "center",
            overHidden: true,
          },
        ],
      },
      fileGridRowBtn:[
          {
              label: '下载',
              emit: "row-download",
          },
          {
              label: '删除',
              emit: "row-del",
          },
      ],
      areaOptions: {
        label: 'title',
        value: 'value',
        children: 'children',
        lazy: true, // 启用懒加载
            lazyLoad: function (node, resolve) {
              const parentCode = (node.level === 0) ? "10" : node.data.id;
              getLazyTree(parentCode).then(res => {
                resolve(res.data.data.map(item => {
                  return {
                    ...item,
                    leaf: !item.hasChildren
                  }
                }))
              });
            },
      }
    };
  },

  created() {
    this.nodeData = this.$route.query.row
      ? JSON.parse(this.$route.query.row)
      : {};
    this.type = this.$route.query.type;
    if (this.nodeData.id){
      this.getDetail(this.nodeData.id)
    } else {
      this.dataForm.acceptOrg = this.userInfo.dept_id
      this.dataForm.punishClass = '1'
    }
  },

  mounted () {
    if(this.type == 'view'){
      this.$refs.formLayout.allDisabled = true
    }
    this.getDictory();
  },

  computed: {
    ...mapGetters(["permission", "colorName", "userInfo"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduquestions_add, true),
        viewBtn: this.vaildData(this.permission.eduquestions_view, true),
        editBtn: this.vaildData(this.permission.eduquestions_edit, true),
        delBtn: this.vaildData(this.permission.eduquestions_delete, true),
      };
    },
    formOptions() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "文件名称",
            prop: "documentName",
            placeholder: "请填写",
            span: 8,
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "blur",
              },
              {
                max: 120,
                message: "文件名称不能超过120个字符",
                trigger: "blur",
              },
            ],
            maxlength:255,
          },
          {
            label: "文件编号",
            prop: "documentCode",
            placeholder: "请填写",
            span: 8,
            maxlength:255,
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "blur",
              },
              {
                max: 120,
                message: "文件编号不能超过120个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "执法机构",
            prop: "executeGov",
            placeholder: "请填写",
            span: 8,
            maxlength:255,
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "blur",
              },
              {
                max: 120,
                message: "执法机构不能超过120个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "处罚金额",
            prop: "fine",
            placeholder: "请填写",
            span: 8,
            maxlength: 50,
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "blur",
              },
              {
                pattern:
                  /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
                message: "请输入正确数值",
              },
            ],
          },
          {
            label: "处罚下达日期",
            prop: "publishDate",
            placeholder: "请选择",
            span: 8,
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "change",
              },
            ],
          },
          {
            label: "要求整改日期",
            prop: "changeDate",
            placeholder: "请选择",
            span: 8,
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "change",
              },
            ],
          },
          {
            label: "接受单位",
            prop: "acceptOrg",
            placeholder: "请填写",
            span: 8,
            formslot: true,
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "blur",
              },
            ],
          },
          {
            label: "境内外",
            prop: "prjDomestic",
            placeholder: "请选择",
            span: 8,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict/dictionary-tree?code=domestic_overseas",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "change",
              },
            ],
          },
          {
            label: "整改进度",
            prop: "schedule",
            placeholder: "请填写",
            span: 8,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary-tree?code=schedule",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "change",
              },
            ],
          },
          {
            label: "处罚事项",
            prop: "punishDesc",
            placeholder: "请填写",
            span: 24,
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "blur",
              },
              {
                max: 255,
                message: "处罚事项不能超过255个字符",
                trigger: "blur",
              },
            ],
            type: "textarea",
            overHidden: true,
            minRows: "2",
            maxRows: "4",
            maxlength: 255,
            showWordLimit: true,
          },
          {
            label: "整改措施",
            prop: "changeDesc",
            placeholder: "请填写",
            span: 24,
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "blur",
              },
              {
                max: 255,
                message: "整改措施不能超过255个字符",
                trigger: "blur",
              },
            ],
            type: "textarea",
            overHidden: true,
            minRows: "2",
            maxRows: "4",
            maxlength: 255,
            showWordLimit: true,
          },
          {
            label: "因安全执法受到处罚的人数",
            prop: "punishNum",
            placeholder: "请填写",
            span: 8,
            rules: [
              {
                required: false,
                message: "请填写",
                trigger: "blur",
              },
              { pattern: /^[0-9]*[1-9][0-9]*$/, message: "请输入正整数" },
            ],
            maxlength: 255,
          },
          {
            label: "政府部门通报",
            prop: "govReport",
            placeholder: "请选择",
            span: 8,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary-tree?code=is_report",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "change",
              },
            ],
          },
          {
            label: "新闻、媒体、网站报道等",
            prop: "mediaReport",
            placeholder: "请选择",
            span: 8,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary-tree?code=is_report",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "change",
              },
            ],
          },
          {
            label: "环保处罚类别",
            prop: "punishType",
            placeholder: "请选择",
            span: 8,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary-tree?code=punish_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "处罚类型",
            prop: "punishClass",
            placeholder: "请选择",
            span: 8,
            type: "select",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary-tree?code=punish_class",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            rules: [
              {
                required: true,
                message: "请填写",
                trigger: "change",
              },
            ],
          },
          {
            label: "处罚地",
            prop: "punishArea",
            placeholder: "请选择",
            span: 8,
            formslot: true,
            rules: [
              {
                required: true,
                message: "请选择",
                trigger: 'change'
              },
            ],
          },
          {
            label: "环保执法文书主要处理处罚意见",
            prop: "punishOpinion",
            placeholder: "请填写",
            span: 24,
            type: "textarea",
            overHidden: true,
            minRows: "2",
            maxRows: "4",
            maxlength: 255,
            showWordLimit: true,
            rules: [
              {
                max: 255,
                message: "环保执法文书主要处理处罚意见不能超过255个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "备注",
            prop: "remark",
            placeholder: "请填写",
            span: 24,
            type: "textarea",
            overHidden: true,
            minRows: "2",
            maxRows: "4",
            maxlength: 255,
            showWordLimit: true,
            rules: [
              { max: 255, message: "备注不能超过255个字符", trigger: "blur" },
            ],
          },
        ],
      };
    },
    headTopBtnOptions() {
      let buttonBtn = [];
      if (this.type != "view") {
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
          loading: this.saveLoading,
        });
        buttonBtn.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-save-back",
          type: "button",
          icon: "",
          btnOptType: "saveBack",
          loading: this.saveLoading,
        });
      }
      buttonBtn.push({
        label: this.$t("cip.cmn.btn.celBtn"),
        emit: "head-cancel",
        type: "button",
        icon: "",
      });
      return buttonBtn;
    },
  },

  methods: {
    getDetail(id) {
      grgovpunishDetail({ id }).then((res) => {
        const data = res.data.data;
        this.dataForm = data;
        this.dataForm.prjDomestic = data.prjDomestic.toString();
        this.dataForm.schedule = data.schedule.toString();
        this.dataForm.govReport = data.govReport.toString();
        this.dataForm.mediaReport = data.mediaReport.toString();
        this.dataForm.punishType = data.punishType == -1 ? '' : data.punishType.toString();
        this.dataForm.punishClass = data.punishClass.toString();
        this.dataForm.punishNum = data.punishNum == -1 ? '' : data.punishNum;
        this.dataForm.punishArea = data.punishArea.split(',')
        // const fileData = JSON.parse(data.files)
        this.$refs.uploader.initTaleData(data.files);
      });
    },
    handleHeadGoback() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave(val) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.dataForm.id)
            this.dataForm.belongOrgId = this.nodeData.belongOrgId;
            this.dataForm.punishArea = this.dataForm.punishArea.toString()
          this.$loading();
          grgovpunishSubmit({ ...this.dataForm }).then((res) => {
            this.$refs.formLayout.$refs.form.allDisabled = false;
            if (val == 1 && !this.nodeData.id) {
              this.$router.$avueRouter.closeTag();
              this.$router.replace({
                path: "/business/responsibility/administrativePenalty/edit",
                query: {
                  row: JSON.stringify({ id: res.data.data.id }),
                  type: "edit",
                },
              });
            }
            if (val == 2) {
              this.handleHeadGoback();
            }
            this.$loading().close();

          });
        }
      });
    },
    // 获取接受单位数据
    getDictory() {
      deptChildTree().then((res) => {
        this.organDic = [res.data.data];
        // this.recurTree(this.organDic);
      });
    },
    // 接受单位切换数据获取label
    handleChangeContract(value) {
      const selectedLabel = this.getNodeLabel(this.organDic, value);
      // this.dataForm.contractName=selectedLabel
    },
    getNodeLabel(nodes, value) {
      for (let node of nodes) {
        if (node.id === value) {
          return node.title;
        }
        if (node.children && node.children.length > 0) {
          const label = this.getNodeLabel(node.children, value);
          if (label) {
            return label;
          }
        }
      }
      return null;
    },
    // 附件上传更新数据
    handleUpload(data) {
      this.dataForm.files = this.resetFileData(data);
    },
    //   组合附件数据
    resetFileData(data) {
      let arr = [];
      data.forEach((ele, ind) => {
        arr.push({
          createTime: ele.createTime,
          createUser: ele.createUser,
          createUserName: ele.createUserName,
          extension: ele.extension,
          fileSize: ele.fileSize,
          id: ele.id,
          path: ele.path,
          schedule: ele.schedule,
          uid: ele.uid,
          name: ele.name,
          size: ele.size,
        });
      });
      return JSON.stringify(arr);
    },

    handleAreaChange(value) {
      this.dataForm.punishAreaName = this.$refs.punishArea.getCheckedNodes()[0].pathLabels.join('/')
    }
  },
};
</script>

<style lang="scss" scoped>
.file-name {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
.headInfo {
  padding: 15px 12px 30px 12px;
  font-size: 14px;
  color: #3f4448;
  label {
    width: 100px;
  }
}

.file-table {
  width: 100%;
  text-align: center;
  border-color: #cccccc;
  font-size: 14px;
  color: #606266;
}
</style>
